@use "../abstracts" as *;

/* ttl
********************************************** */
/* --- page_ttl --- */
.page_ttl {
  position: relative;
  z-index: 10;
  margin-top: 140px;
  overflow: hidden;
  height: 460px;
  @include mq(sp) {
    height: 180px;
    margin-top: 64px;
  }
}
.page_ttl--bg-wrap {
  height: 550px;
  @include mq(sp) {
    height: 120px;
  }
}
.page_ttl .section_pdg {
  margin-left: 18.75vw;
  margin-right: 18.75vw;
  @include mq(sp) {
    width: 100%;
    margin-left: 12vw;
    margin-right: 12vw;
  }
}
.page_ttl--bg {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  width: 91.875vw;
  min-width: 992px;
  background-size: cover;
  background-position: center;
  background-color: #eee;
  @include mq(sp) {
    width: 94vw;
    min-width: inherit;
  }
}
.page_ttl-jp {
  font-size: rem(48);
  font-family: ten-mincho, serif;
  font-weight: 400;
  font-style: normal;
  text-align: left;
  color: #fff;
  @include mq(sp) {
    font-size: 2rem; /* 28px */
  }
}
.page_ttl-en {
  display: block;
  text-transform: uppercase;
  color: #fff;
  font-size: rem(18);
  font-family: 'EB Garamond', serif;
  letter-spacing: .1em;
  text-align: left;
  @include mq(sp) {
    font-size: 1.143rem; /* 16px */
  }
}
.ttl-en {
  display: block;
  color: $clr-bro-thin;
  font-size: 1.25rem;
  font-family: 'EB Garamond', serif;
  letter-spacing: .1em;
  @include mq(sp) {
    font-size: 1.125rem;
    line-height: 1;
  }
}
.ttl-wood {
  margin-bottom: 34px;
  @include mq(sp) {
    margin-bottom: 20px;
    width: 107px;
  }
}

/* --- ttl --- */
.ttl-01 {
  font-size: 3rem; /* 48px */
  color: var(--clr-main, #1d7fb7);
  line-height: 1.416;
  @include mq(sp) {
    font-size: 2.143rem; /* 30px */
  }
}
.lps_sec .ttl-01 {
  position: relative;
  margin-top: 118px;
  @include mq(sp) {
    margin-top: 60px;
  }
}
.lps_sec .ttl-01::before {
  content: "";
  position: absolute;
  top: -34px;
  left: 50%;
  transform: translate(-50%, -100%);
  width: 212px;
  height: 164px;
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/ttl-wood.png);
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  @include mq(sp) {
    top: -18px;
    width: 106px;
    height: 82px;
  }
}
.lps_sec:nth-child(2n) .ttl-01::before {
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/ttl-wood-wh.png);
}
.lps_sec .ttl-01 img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.home .ttl-01 {
  font-family: ten-mincho, serif;
  font-weight: 400;
  font-style: normal;
  @include mq(sp) {
    font-size: 2.143rem;
  }
}
.home_ttl:where(:not(.txt-vl)) .ttl-01 {
  margin-bottom: 12px;
}
.ttl-02 {
  color: var(--clr-main, #1d7fb7);
  font-size: 2.5rem; /* 40px */
  font-family: ten-mincho, serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1.625;
  @include mq(sp) {
    font-size: 2rem; /* 28px */
  }
}
.home .ttl-02 {
  @include mq(sp) {
    font-size: 1.875rem;
  }
}
.lps_parts--img_text .inner-lg .ttl-03 {
  font-size: 2.5rem; /* 40px */
  @include mq(sp) {
    font-size: 1.714rem; /* 24px */
  }
}
.ttl-03 {
  color: var(--clr-main, #1d7fb7);
  font-size: 2rem; /* 32px */
  font-family: ten-mincho, serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1.625;
  @include mq(sp) {
    font-size: 1.714rem; /* 24px */
  }
}
.lps_sec .ttl-01,
.lps_sec .ttl-02,
.lps_sec .ttl-03 {
  position: relative;
  z-index: 1;
}
.lps_sec .ttl-01,
.lps_sec .ttl-02,
.lps_sec .ttl-03:where(:not(:last-child)) {
  margin-bottom: 1.5em;
  @include mq(sp) {
    margin-bottom: 1em;
  }
}
.lps_parts--column .ttl-03 {
  margin-bottom: .8em;
}
.ttl-04 {
  color: var(--clr-main, #1d7fb7);
  font-size: 1.75rem;/* 28px */
  font-family: ten-mincho, serif;
  font-weight: 400;
  font-style: normal;
}
.lps_sec .column-3 .ttl-03,
.lps_sec .column-4 .ttl-03 {
  font-size: 1.625rem; /* 26px */
  @include mq(sp) {
    font-size: 1.714rem; /* 24px */
  }
}
