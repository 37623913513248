@use '../abstracts' as * ;

/* ##############################################################################

    FOOTER

############################################################################## */

  .footer {
    padding-bottom: 90px;
    background-color: #fff;
    @include mq(sp) {
      padding-bottom: 40px;
    }
  }
  .footer::before {
    content: "";
    position: absolute;
    bottom: 138px;
    left: 0;
    z-index: 0;
    width: 100vw;
    height: 58.43vw;
    background-image: url(/wp/wp-content/themes/standard_sass/images/top/top-bg02.png);
    background-size: cover;
    background-repeat: no-repeat;
    pointer-events: none;
  }
  .home .footer::before {
    opacity: 0;
  }
  .home .footer.wayshow::before {
    animation-name: blur-tra;
    animation-duration: 2s;
    animation-timing-function: cubic-bezier(.08, .41, .19, .95);
    animation-fill-mode: forwards;
  }

/* page_fixed
********************************************** */
  .page_fixed {
    position: fixed;
    bottom: 24px;
    left: 24px;
    width: 64px;
    height: 64px;
    z-index: 999;
    border: 1px solid #C5D1C6;
    border-radius: 50%;
    cursor: pointer;
  }
  .page_fixed .speaker {
    margin-right: 5px;
  }
  .page_fixed .on {
    display: none;
  }
  .page_fixed.active .on {
    display: block;
  }
  .page_fixed.active .off {
    display: none;
  }

  @include mq(sp) {
    .page_fixed {
      width: 40px;
      height: 40px;
      bottom: 16px;
      left: 16px;
    }
    .page_fixed .speaker {
      width: 14px;
    }
    .page_fixed .on {
      width: 8px;
    }
    .page_fixed .off {
      width: 8px;
    }
  }

/* bnr_fixed
********************************************** */
  .bnr_fixed {
    position: fixed;
    bottom: 24px;
    right: 24px;
    width: 140px;
    height: 140px;
    z-index: 999;
    cursor: pointer;
  }
  .bnr_fixed a {
    display: block;
  }
  .bnr_fixed a:hover {
    opacity: .8;
  }

  @include mq(sp) {
    .bnr_fixed {
      width: 80px;
      height: 80px;
      bottom: 16px;
      right: 16px;
    }
  }

/* footer_top
********************************************** */
  .footer_top.section_pdg {
    padding-top: 11.71vw;
  }
  .footer_top .inner {
    position: relative;
    width: 95%;
    max-width: 1200px;
  }
  .footer_info {
    width: max(56vw, 1000px);
    margin: 0 auto 92px;
  }
  .footer_logo {
    width: 297px;
    margin-bottom: 16px;
  }
  .footer_logo a {
    display: block;
  }
  .footer_logo img {
    width: 100%;
  }
  .footer_info .txt {
    line-height: 2;
  }
  .footer_info .footer--sns {
    margin-top: 24px;
  }
  .footer_info .footer--sns .fb {
    margin-right: 14px;
  }
  .footer_info .footer--sns a {
    display: flex;
  }
  .footer_info .map {
    width: 67.88%;
  }
  .footer_info .map_box {
    width: calc(50% - 10px);
    height: 232px;
    border: 2px solid #D2D2D2 !important;
  }
  .footer_info iframe{
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 1080px) {
    .footer_top .inner {
      width: 90%;
    }
  }
  @include mq(sp) {
    .footer_info .info_txt {
      margin-bottom: 24px;
    }
    .footer_info {
      width: 100%;
      margin-bottom: 20px;
    }
    .footer_info .footer--sns {
      margin-top: 12px;
    }
    .footer_info .footer--sns .fb {
      margin-right: 8px;
    }  
    .footer_info .map {
      width: 100%;
    }
    .footer_info .map_box {
      height: 200px;
      margin-bottom: 50px;
    }
  }
  @include mq(500) {
    .footer_info .map {
      flex-direction: column;
    }
    .footer_info .map_box {
      width: 100%;
    }
  }

  /* ---fnav --- */
  .fnav--menu:first-child {
    margin-bottom: 10px;
    @include mq(sp) {
      margin-bottom: 0;
    }
  }
  .fnav--menu a {
    display: block;
    padding: 0 20px;
    &:hover {
      opacity: .7;
    }
    @include mq(sp) {
      position: relative;
      padding: .5em .5em .5em 0;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        width: 6px;
        height: 6px;
        border-top: 1px solid;
        border-right: 1px solid;
        transform: rotate(45deg);
      }
    }
  }

  @media screen and (max-width: 1250px) {
    .fnav--menu a {
      padding: 0 10px;
    }
  }

  .fnav--menu .menu-item {
    position: relative;
  }
  .fnav--menu .menu-item:not(:last-child)::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 1px;
    height: 16px;
    background-color: $clr-blk;
    @include mq(sp) {
      content: inherit;
    }
  }

  /* ---sns_area --- */
  .sns_area {
    margin-top: 40px;
    @include mq(sp) {
      margin-top: 24px;
    }
  }
  .sns--list li:not(:last-child) {
    margin-right: .5rem;
  }
  .sns--list a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.5rem; /* 24px */
  }
  .sns--list a:hover {
    opacity: .7;
  }

/* footer_btm
*************************************************** */
  .footer_btm {
    padding: 8px 0;
  }
  .copyright {
    margin-right: 26px;
    color: #BEBEBE;
    font-family: 'Lato', sans-serif;
  }
  .pbl {
    padding-bottom: 2px;
  }
  .pbl a {
    opacity: .5;
    &:hover {
      opacity: 1;
    }
  }

  @include mq(sp) {
    .copyright {
      margin-right: 1em;
    }
  }
