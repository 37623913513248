@use '../abstracts' as *;

/* contact
********************************************** */
.contact .cta_tel {
  width: 100%;
  max-width: 400px;
  @include auto-margin;
}
.contact .cta_tel a {
  padding: 24px 40px;
  @include mq(sp) {
      display: block;
      padding: 14px;
  }
}
.contact .cta_tel .tel {
  font-size: 2rem;
  text-align: center;
}
.contact .cta_tel .num {
  font-size: 2.5rem;
}
.contact .cta_tel .hours {
  font-size: 1.5rem;
  text-align: center;
}

@include mq(sp) {
  .contact .cta_tel .tel {
    font-size: 1.429rem;
  }
  .contact .cta_tel .num {
    font-size: 2.571rem;
  }
  .contact .cta_tel .hours {
    font-size: 1.5rem;
  }
}