/* ##############################################################################

    KEYFRAMES

############################################################################## */

/* 普通にフェード（ちょっと拡大つき）
********************************************** */
@keyframes hero_anime {
    0% { -webkit-mask-position: -100% 0; }
    100% { -webkit-mask-position: 100% 0; }
}

@keyframes people-slider {
    0% {
        transform: translate( -25% ,0);
    }
    100% {
        transform: translate( -75%, 0);
    }
}

@keyframes op1 {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes op2 {
    0% {
        opacity: 0;
        transform: translate(-10px);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}
@keyframes op-txt {
    100% {
        opacity: 1;
        transform: translate(0);
    }
}
@keyframes op-img {
    100% {
        opacity: 1;
        transform: translate(0);
    }
}
@keyframes op-blur {
    100% {
        opacity: 1;
        filter: blur(0);
        transform: scale(1, 1);
    }
}
@keyframes op-blur-tra {
    100% {
        opacity: 1;
        filter: blur(0);
        transform: scale(1, 1) translateY(-50%);
    }
}

@keyframes blur {
    0% {
        opacity: 0;
        filter: blur(10px);
    }

    100% {
        opacity: 1;
        filter: blur(0px);
    }
}

@keyframes blur-tra {
    0% {
        opacity: 0;
        filter: blur(10px);
        transform: translateY(-50px);
    }

    100% {
        opacity: 1;
        filter: blur(0px);
        transform: translateY(0);
    }
}

@keyframes clip-path {
    0% {
        clip-path: inset(0 100% 0 0);
    }

    100% {
        clip-path: inset(0);
    }
}