@use '../abstracts' as *;
/* home　共通
********************************************** */
.preparation {
  width: 100%;
}
/* hero
********************************************** */
.hero {
  position: relative;
  height: auto;
}
.hero::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 1053px;
  height: 632px;
  background-image: url(/wp/wp-content/themes/standard_sass/images/top/top-bg01.png);
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
  pointer-events: none;
  opacity: 0;
}
.hero.wayshow::before {
  animation-name: blur-tra;
  animation-duration: 2s;
  animation-delay: 4s;
  animation-timing-function: cubic-bezier(.08, .41, .19, .95);
  animation-fill-mode: forwards;
}
.hero_inner {
  padding-top: clamp(170px, 11.87vw, 228px);
  padding-left: 8.125vw;
}
.hero_ttl {
  margin-bottom: 42px;
}
.hero_ttl::before {
  content: "";
  position: absolute;
  top: 24%;
  left: 29%;
  transform: translate(-50%, -50%);
  width: 122px;
  height: 145px;
  background-image: url(/wp/wp-content/themes/standard_sass/images/top/hero-leaf.png);
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
  pointer-events: none;
  opacity: 0;
}
.hero.wayshow .hero_ttl::before {
  animation-name: blur;
  animation-duration: 2s;
  animation-delay: 4s;
  animation-timing-function: cubic-bezier(.08, .41, .19, .95);
  animation-fill-mode: forwards;
}
.hero_ttl .jp {
  font-size: rem(54);
  color: $clr-bro;
}
.hero_ttl .jp span {
  opacity: 0;
  transform: translateX(-30px);
  display: inline-block;
}
.hero.wayshow .hero_ttl .jp span {
  animation-name: op2;
  animation-duration: .5s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}
.hero_ttl .en {
  color: $clr-bro-thin;
  font-size: rem(20);
  letter-spacing: .1em;
}
.hero_ttl .en span {
  opacity: 0;
  transform: translateX(-30px);
  display: inline-block;
}
.hero.wayshow .hero_ttl .en span {
  animation-name: op2;
  animation-duration: .5s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}
.hero_slides {
  overflow: hidden;
  pointer-events: none;
}
.hero_slides-left,
.hero_slides-right {
  clip-path: inset(0 100% 0 0);
}
.hero.wayshow .hero_slides-left,
.hero.wayshow .hero_slides-right {
  animation-name: clip-path;
  animation-duration: 2s;
  animation-delay: 3s;
  animation-timing-function: cubic-bezier(.08, .41, .19, .95);
  animation-fill-mode: forwards;
}
.hero_slides-left {
  width: 59.3%;
  height: 45.2vw;
  margin-right: 1.0416vw;
}
.hero_slides-right {
  width: calc(100% - 58.9569% - 1.0416vw);
  height: 45.2vw;
  flex: 1;
}
.hero_slide-sp {
  display: none !important;
}
.hero_slides,
.hero_slide,
.hero_slide .slick-list,
.hero_slide .slick-track,
.hero_slide .slick-slider,
.hero_slide .slick-slide {
  width: 100%;
  height: 100% !important;
}
.hero_slide .slick-list {
  position: relative;
  overflow: hidden;
}
.hero_slides-left .slick-slide,
.hero_slides-right .slick-slide {
  position: relative;
  overflow: hidden;
  -webkit-mask-image: radial-gradient(circle farthest-corner at 100% 0%, #000 60%, transparent 90%);
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: 200% 100%;
  -webkit-mask-position: -100% 0;
  transition-property: -webkit-mask-position;
  transition-property: mask-position;
  transition-property: mask-position, -webkit-mask-position;
  transition-duration: 5s;
  transition-timing-function: cubic-bezier(.3, .1, .1, 1);
}
.hero_slides-left .slide-animation,
.hero_slides-right .slide-animation {
  animation: hero_anime 3s forwards;
}

@include mq(sp) {
  .hero_slide-pc {
    display: none !important;
  }
  .hero_slide-sp {
    display: block !important;
  }
  .hero::before {
    width: 100vw;
    height: 60vw;
  }
  .hero_inner {
    padding-top: 94px;
    padding-left: inherit;
    width: 94%;
    margin-left: auto;
  }
  .hero_ttl {
    margin-bottom: 14px;
  }
  .hero_ttl::before {
    top: 24%;
    left: 250px;
    width: 61px;
    height: 73px;
  }
  .hero_ttl .jp {
    font-size: 2.25rem;;
    line-height: 1.2;
  }
  .hero_ttl .en {
    font-size: 1.125rem;;
  }
  .hero_slides-left {
    width: 100%;
    height: inherit;
    margin-right: inherit;
    margin-bottom: 10px;
  }
  .hero_slides-right {
    width: 100%;
    height: inherit;
    flex: inherit;
  }
}

/* home_concept
********************************************** */
.home_concept.section_pdg {
  padding-bottom: 0;
}
.home_concept::before {
  content: "";
  position: absolute;
  top: -10.41vw;
  left: 0;
  width: 100vw;
  height: 58.4375vw;
  background-image: url(/wp/wp-content/themes/standard_sass/images/top/top-bg02.png);
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
  pointer-events: none;
  opacity: 0;
}
.home_concept.wayshow::before {
  animation-name: blur-tra;
  animation-duration: 2s;
  animation-delay: 1s;
  animation-timing-function: cubic-bezier(.08, .41, .19, .95);
  animation-fill-mode: forwards;
}
.home_concept .concept_inner {
  max-width: 1467px;
  margin-left: 11.35vw;
  transform: translate3d(0, 74px, 0);
}
.home_concept .sugi {
  bottom: 6.77vw;
  left: 5.973vw;
  z-index: -1;
  width: 162px;
  height: 187px;
}
.home_concept .home_ttl {
  margin-right: 6.25vw;
}
.home_concept .imgarea {
  margin-top: 48px;
  margin-left: 40px;
}
.home_concept .hiba {
  top: -114px;
  left: -129px;
  z-index: -1;
  width: 148px;
  height: 176px;
}
.home_concept .txt {
  width: 341px;
  margin-top: 9.89vw;
}
.concept_video {
  position: relative;
  height: 38.54vw;
  overflow: hidden;
}
.concept_video video {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  object-fit: cover;
}
@media screen and (max-width: 1400px) {
  .home_concept .concept_inner {
    margin-left: 4vw;
  }
  .home_concept .txt {
    margin-top: 7vw;
  }
}
@media screen and (max-width: 1200px) {
  .home_concept .txtarea {
    margin-top: -96px;
  }
}
@include mq(sp) {
  .home_concept .concept_inner {
    margin-left: inherit;
    overflow-x: hidden;
  }
  .home_concept .sugi {
    top: 50px;
    bottom: inherit;
    left: inherit;
    right: 5%;
    z-index: -1;
    width: 81px;
    height: 94px;
  }
  .home_concept .txtarea {
    width: 88%;
    margin: 0 auto;
  }
  .home_concept .home_ttl {
    margin-right: 20px;
  }
  .home_concept .imgarea {
    margin-top: 40px;
    margin-left: 6%;
  }
  .home_concept .hiba {
    top: -110px;
    left: -20px;
    z-index: 0;
    width: 74px;
    height: 88px;
  }
  .concept_video {
    height: min(80vw, 300px);
  }
}

/* home_modelhouse
********************************************** */
.home_modelhouse {
  position: relative;
  padding-bottom: 60px;
}
.home_modelhouse .modelhouse_inner {
  margin-bottom: 5.72vw;
}
.home_modelhouse .home_ttl {
  position: relative;
  padding-top: 198px;
}
.home_modelhouse .home_ttl::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 213px;
  height: 164px;
  background-image: url(/wp/wp-content/themes/standard_sass/images/top/ttl-wood.png);
  background-position: bottom;
  background-repeat: no-repeat;
}
.home_modelhouse .contentarea {
  padding: min(10.29vh, 100px) 0;
  height: 84.03vh;
  max-height: 816px;
  overflow-x: hidden;
}
.home_modelhouse .contentarea::before {
  content: "";
  position: absolute;
  top: 0;
  left: 6.5vw;
  width: 87vw;
  height: 100%;
  background-image: url(/wp/wp-content/themes/standard_sass/images/top/showhouse-bg_sp.svg);
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
  pointer-events: none;
}
.home_modelhouse .scroll_arrow {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  padding-bottom: 40px;
  display: none;
  color: $clr-gre;
}
.home_modelhouse .scroll_arrow::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 1px;
  height: 36px;
  background-color: $clr-gra;
}
.home_modelhouse .btn {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 310px;
  z-index: 1;
}
.side_scroll {
  position: absolute;
  top: min(10.29vh, 100px);
  padding: 0 6.45vw;
  width: content;
}
.side_scroll .modelhouse_box {
  margin-right: 100px;
}
.side_scroll .txtarea {
  position: relative;
  width: 320px;
  margin-right: 32px;
}
.side_scroll .txtarea_wrapper {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
}
.side_scroll .txtarea img {
  margin-bottom: 26px;
}
.side_scroll .kana {
  color: $clr-gre;
  line-height: 1.8;
}
.side_scroll .box-ttl {
  margin-bottom: 32px;
  color: $clr-gre;
  font-size: rem(23);
  line-height: 2;
}
.side_scroll .box-ttl span {
  display: block;
  font-size: rem(72);
  line-height: 1;
}
.side_scroll .box-txt {
  margin-bottom: 20px;
  line-height: 2;
}
.side_scroll .model {
  color: #AAAAAA;
}
.side_scroll .imgarea {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: min(35vh,371px) min(35vh,371px) min(37.2vh,396px) min(35vh,371px) min(35vh,371px);
  gap: 10px;
}
.side_scroll .imgarea img {
  display: block;
  width: 100%;
  height: auto;
}
.side_scroll .imgarea img:nth-of-type(1) {
  grid-row: 1 / 3;
  grid-column: 1 / 3;
}
.side_scroll .imgarea img:nth-of-type(2) {
  grid-row: 1 / 2;
  grid-column: 3 / 4;
}
.side_scroll .imgarea img:nth-of-type(3) {
  grid-row: 2 / 3;
  grid-column: 3 / 4;
}
.side_scroll .imgarea img:nth-of-type(4) {
  grid-row: 1 / 3;
  grid-column: 4 / 6;
}
@media screen and (max-height: 750px) {
  .side_scroll .imgarea {
    grid-template-columns: min(32vh, 371px) min(32vh, 371px) min(34vh, 396px) min(32vh, 371px) min(32vh, 371px);
  }
  .side_scroll .txtarea img {
    margin-bottom: 14px;
  }
  .side_scroll .box-ttl {
    margin-bottom: 18px;
  }
  .side_scroll .box-txt {
    margin-bottom: 12px;
  }
}
@include mq(sp) {
  .home_modelhouse .home_ttl::before {
    width: 106px;
    height: 82px;
    background-size: cover;
  }
  .home_modelhouse .home_ttl {
    align-items: center;
    padding-top: 100px;
  }
  .home_modelhouse .contentarea {
    height: min(125vw, 600px);
    padding: min(25vw, 80px) 0;
  }
  .home_modelhouse .scroll_arrow {
    display: block;
  }
  .home_modelhouse .btn {
    width: 90%;
  }
  .side_scroll {
    top: min(25vw, 80px);
  }
  .side_scroll .txtarea {
    width: 140px;
    margin-right: 10px;
  }
  .side_scroll .txtarea img {
    margin-bottom: 12px;
    width: 52px;
  }
  .side_scroll .kana {
    font-size: 1rem;
  }
  .side_scroll .box-ttl {
    margin-bottom: 16px;
    font-size: 1.25rem;
  }
  .side_scroll .box-ttl span {
    font-size: 3rem;
  }
  .side_scroll .box-txt {
    margin-bottom: 10px;
    font-size: 1rem;
  }
  .side_scroll .model {
    font-size: 0.8rem;
  }
  .side_scroll .imgarea {
    grid-template-columns: min(45vw, 268px) min(45vw, 268px) min(47.7vw, 286px) min(45vw, 268px) min(45vw, 268px);
    gap: 5px;
  }
  .side_scroll .more {
    padding-bottom: 24px;
    width: 100px;
    height: 100px;
  }
  .side_scroll .more::before {
    bottom: 12%;
  }
  .side_scroll .more span {
    line-height: 1.8;
    font-size: 1rem;
  }
}

/* home_view
********************************************** */
.home_view.section_pdg {
  padding-top: 90px;
  padding-bottom: 90px;
}
.home_view .home_ttl {
  position: absolute;
  top: 50%;
  right: 6.77vw;
  transform: translateY(-50%) scale(1.1, 1.1);
}
.home_view .ttl-01 {
  color: #fff;
  white-space: nowrap;
}
@media screen and (max-width: 1600px) {
  .home_view .ttl-01 {
    font-size: 3vw;
  }
}
@include mq(sp) {
  .home_view.section_pdg {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .home_view .ttl-01 {
    font-size: 1.8rem;
  }
  .home_view img {
    height: 400px;
  }
}

/* home_sawano
********************************************** */
.home_sawano.section_pdg {
  padding-top: 90px;
  padding-bottom: 90px;
}
.sawano_inner::before {
  content: "";
  position: absolute;
  top: 4.68vw;
  right: 0;
  width: 76vw;
  height: 1000px;
  background-image: url(/wp/wp-content/themes/standard_sass/images/top/sawanohouse-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
  pointer-events: none;
}
.home_sawano .sawano_top {
  max-width: 1752px;
  margin-bottom: 120px;
}
.home_sawano .home_ttl {
  position: absolute;
  top: 3.64vw;
  right: 5.2vw;
  z-index: 1;
}
.home_sawano .ttl-01 {
  color: $clr-wh;
}
.home_sawano .imgarea {
  max-width: 1024px;
}
.home_sawano .imgarea .sawano_slider {
  pointer-events: none;
}
.home_sawano .txtarea {
  max-width: 584px;
  margin: 10.93vw 30px 0 30px;
}
.home_sawano .ttl-02 {
  margin-bottom: 36px;
}
.home_sawano .txtarea .txt {
  color: $clr-bro;
}
.home_sawano .point-ttl {
  padding-left: 92px;
  color: $clr-bro-thin;
  font-size: rem(24);
  font-family: ten-mincho, serif;
  font-weight: 400;
  font-style: normal;
}
.home_sawano .point-ttl::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 72px;
  height: 1px;
  background-color: $clr-bro-thin;
}
.home_sawano .points {
  margin-bottom: 78px;
}
.home_sawano .point_box {
  position: relative;
  width: calc(100% / 4 - 24px / 3);
  padding-top: calc(100% / 4 - 24px / 3);
  background-image: url(/wp/wp-content/themes/standard_sass/images/top/sawanohouse-point.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.home_sawano .point_box::before {
  counter-increment: number 1;
  content: "0" counter(number);
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: $clr-wh;
  font-size: rem(24);
  font-family: 'EB Garamond', serif;
  line-height: 1;
}
.home_sawano .point-txt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -30%);
  color: $clr-bro;
  font-size: rem(18);
  font-family: ten-mincho, serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1.555;
  text-align: center;
  white-space: nowrap;
}
.home_sawano .sawano_btm_inner {
  max-width: 1650px;
  margin: 0 auto;
}
.home_sawano .link_box {
  width: calc(50% - 10px);
  max-height: 372px;
  overflow: hidden;
}
.home_sawano .link_box > a {
  display: block;
}
.home_sawano .link_box img {
  transition: var(--transit-default, all .4s ease-out);
}
.home_sawano .link_box:hover img {
  transform: scale(1.05);
}
.home_sawano .box-txtarea {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 82.7%;
  color: $clr-wh;
}
.home_sawano .box-txtarea::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  display: block;
  width: 28px;
  height: 28px;
  border-top: solid 3px $clr-wh;
  border-right: solid 3px $clr-wh;
  transform: rotate(45deg);
  transition: all 0.4s ease-out;
}
.home_sawano .box-txtarea .ttl {
  margin-bottom: 32px;
  font-size: rem(32);
  font-family: ten-mincho, serif;
  font-weight: 400;
  font-style: normal;
}
.home_sawano .box-txtarea .txt {
  font-size: rem(15);
  max-width: 422px;
}
@media screen and (max-width: 1752px) {
  .home_sawano .imgarea {
    width: 61.64vw;
  }
}
@media screen and (max-width: 1400px) {
  .home_sawano .sawano_top {
    margin-bottom: 70px;
  }
  .sawano_inner::before {
    height: 850px;
  }
  .home_sawano .txtarea {
    max-width: max(400px, 37vw);
    margin: 8vw 30px 0 30px;
  }
  .home_sawano .ttl-02 {
    margin-bottom: 16px;
  }
  .home_sawano .txtarea .txt {
    margin-bottom: 8px;
  }
  .home_sawano .txt p:first-child {
    margin-bottom: 4px;
  }
  .home_sawano .point_box {
    width: calc(25% - 2px);
    padding-top: calc(25% - 2px);
  }
  .home_sawano .point_box::before {
    top: 15px;
    font-size: rem(22);
  }
  .home_sawano .point-txt {
    font-size: rem(15);
  }
  .home_sawano .points {
    margin-bottom: 56px;
  }
  .home_sawano .link_box {
    width: calc(50% - 5px);
  }
  .home_sawano .box-txtarea {
    width: 90%;
    padding-right: 26px;
  }
  .home_sawano .box-txtarea .ttl {
    margin-bottom: 12px;
    font-size: rem(26);
  }
  .home_sawano .box-txtarea::before {
    width: 20px;
    height: 20px;
  }
}
@media screen and (max-width: 1200px) {
  .home_sawano .point_box::before {
    top: 10px;
    font-size: rem(20);
  }
  .home_sawano .point-txt {
    font-size: rem(13);
  }
}
@include mq(sp) {
  .home_sawano.section_pdg {
    padding-top: 40px;
    padding-bottom: 40px;
    overflow-x: hidden;
  }
  .sawano_inner::before {
    top: 50px;
    height: 100%;
    width: 100vw;
  }
  .home_sawano .sawano_top {
    margin-bottom: 56px;
  }
  .home_sawano .imgarea {
    width: 94vw;
  }
  .home_sawano .points {
    margin-bottom: 40px;
  }
  .home_sawano .txtarea {
    width: 88%;
    margin: 8vw auto 0 auto;
  }
  .home_sawano .points {
    margin-bottom: 40px;
  }
  .home_sawano .sawano_btm {
    margin-bottom: 50px;
  }
  .home_sawano .sawano_btm_inner {
    width: 100%;
  }
  .home_sawano .link_box {
    width: 100%;
  }
  .home_sawano .box-txtarea .ttl {
    margin-bottom: 12px;
    font-size: 1.25rem;
  }
  .home_sawano .box-txtarea .txt {
    font-size: .85rem;
  }
  .home_sawano .box-txtarea::before {
    width: 16px;
    height: 16px;
    border-top: solid 2px #fff;
    border-right: solid 2px #fff;
  }
}

/* home_reform
********************************************** */
.home_reform.section_pdg {
  padding-top: 90px;
  padding-bottom: 90px;
}
.reform_wrap::before {
  content: "";
  position: absolute;
  top: 4.68vw;
  left: 0;
  width: 76vw;
  height: 834px;
  background-image: url(/wp/wp-content/themes/standard_sass/images/top/sawanohouse-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
  pointer-events: none;
}
.home_reform .reform_inner {
  max-width: 1752px;
  margin-bottom: 120px;
  margin-left: auto;
}
.home_reform .home_ttl {
  position: absolute;
  top: 3.754vw;
  left: 2.7vw;
  z-index: 1;
}
.home_reform .ttl-01 {
  color: $clr-wh;
}
.home_reform .imgarea {
  max-width: 1024px;
}
.home_reform .txtarea {
  max-width: 584px;
  margin: 10.93vw 30px 0 30px;
}
.home_reform .ttl-02 {
  margin-bottom: 36px;
  color: $clr-bro;
}
.home_reform .ttl-02 {
  margin-bottom: 36px;
  color: $clr-bro;
}
@media screen and (max-width: 1752px) {
  .home_reform .imgarea {
    width: 56.64vw;
  }
}
@media screen and (max-width: 1400px) {
  .home_view .ttl-01 {
    font-size: 3vw;
  }
  .reform_wrap::before {
    height: 740px;
  }
  .home_reform .txtarea {
    max-width: max(410px, 37vw);
    margin: 8vw 30px 0 30px;
  }
  .home_reform .ttl-02 {
    margin-bottom: 20px;
  }
  .home_reform .txt {
    margin-bottom: 32px;
  }
  .home_reform .txt p:first-child {
    margin-bottom: 4px;
  }
}
@include mq(sp) {
  .home_reform.section_pdg {
    padding-top: 40px;
    padding-bottom: 40px;
    overflow-x: hidden;
  }
  .reform_wrap::before {
    top: 50px;
    width: 100vw;
    height: 100%;
  }
  .home_reform .reform_inner {
    margin-bottom: 50px;
  }
  .home_reform .imgarea {
    width: 92vw;
    margin-left: auto;
  }
  .home_reform .txtarea {
    width: 88%;
    margin: 8vw auto 0 auto;
  }  
}

/* home_works
********************************************** */
.home_works.section_pdg {
  padding-bottom: 7.81vw;
}
.home_works .works_wrapper::before {
  content: "";
  position: absolute;
  top: 200px;
  left: 0;
  width: 100vw;
  height: 58.43vw;
  background-image: url(/wp/wp-content/themes/standard_sass/images/top/top-bg03.png);
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
  pointer-events: none;
  opacity: 0;
}
.home_works.wayshow .works_wrapper::before {
  animation-name: blur-tra;
  animation-duration: 2s;
  animation-delay: 1s;
  animation-timing-function: cubic-bezier(.08, .41, .19, .95);
  animation-fill-mode: forwards;
}
.home_works .works_inner {
  max-width: 1250px;
}
.home_works .posts {
  margin-bottom: 70px;
}
.home_works .posts .post {
  width: calc(100% / 3 - 16px);
  box-shadow: 0 0 12px 2px rgb(0 0 0 / .05);
}
.home_works .posts .post:not(:last-child) {
  margin-right: 24px;
}
.home_works .posts .post--img {
  width: 100%;
  padding-top: 80%;
}
@include mq(sp) {
  .home_works.section_pdg {
    padding-bottom: 60px;
  }
  .home_works .posts .post {
    width: 100%;
    margin-bottom: 20px;
  }
  .home_works .posts {
    margin-bottom: 40px;
  }
}
/* home_event
********************************************** */
.home_event.section_pdg {
  padding-top: 96px;
  padding-bottom: 115px;
  background-image: url(/wp/wp-content/themes/standard_sass/images/top/event-bg.jpg);
}
.home_event .event_inner {
  max-width: 1176px;
}
.home_event .ttl-en {
  color: $clr-wh;
}
.home_event .event-leaf {
  position: absolute;
  bottom: -48px;
  right: -100px;
  z-index: 1;
  pointer-events: none;
}
.home_event .posts {
  margin-bottom: 100px;
}
.home_event .post {
  padding: 12px;
}
.home_event .post .post--link::before {
  z-index: 1;
}
.home_event .post--img {
  position: relative;
  width: 100%;
  border: 2px solid $clr-wh;
}
.home_event .post--img::before {
  content: "";
  display: block;
  padding-top: 69.4%;
}
.home_event .post--img::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: linear-gradient(0deg, #0000004D 0%, #00000000 100%);
}
.home_event .post--txtarea {
  position: absolute;
  bottom: 45px;
  left: 52px;
  width: calc(100% - 104px);
}
.home_event .post--ttl {
  margin-bottom: 2px;
  color: $clr-wh;
  font-size: rem(22);
}
.home_event .schedule {
  color: $clr-wh;
  font-size: rem(14);
}
.home_event .slick-arrow {
  position: absolute;
  top: 48%;
  transform: translateY(-50%);
  z-index: 1;
  width: 63px;
  height: 63px;
}
.home_event .slick-arrow.prev {
  left: -93px;
}
.home_event .slick-arrow.next {
  right: -93px;
}
.home_event .next::before,
.home_event .prev::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  display: block;
  width: 44px;
  height: 44px;
}
.home_event .next::before {
  border-top: solid 3px #FFFFFF;
  border-right: solid 3px #FFFFFF;
  transform: translate(-50%, -50%) rotate(45deg);
}
.home_event .prev::before {
  border-bottom: solid 3px #FFFFFF;
  border-left: solid 3px #FFFFFF;
  transform: translate(-50%, -50%) rotate(45deg);
}
.home_event .slick-dots {
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  width: content;
  height: 3px;
  overflow: hidden;
}
.home_event .slick-dots li {
  width: 32px;
  margin: 0 4px;
}
.home_event .slick-dots button {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-size: 0;
  border: none;
  background-color: $clr-wh;
  cursor: pointer;
}
.home_event .slick-active button {
  background-color: $clr-bro-thin;
}
@media screen and (max-width: 1400px) {
  .home_event .event-leaf {
    bottom: -28px;
    right: -50px;
    width: 100px;
  }
  .home_event .slick-arrow.prev {
    left: 0;
  }
  .home_event .slick-arrow.next {
    right: 0;
  }
}
@include mq(sp) {
  .home_event .event-leaf {
    bottom: -16px;
    right: -8px;
    width: 64px;
  }
  .home_event .post--txtarea {
    bottom: 24px;
    left: 26px;
    width: calc(100% - 52px);
  }
  .home_event .slick-arrow.prev {
    left: -16px;
  }
  .home_event .slick-arrow.next {
    right: -16px;
  }
  .home_event .slick-arrow {
    width: 36px;
    height: 36px;
  }
  .home_event .next::before,
  .home_event .prev::before {
    width: 22px;
    height: 22px;
  }
  .home_event .next::before {
    border-top: solid 2px #FFFFFF;
    border-right: solid 2px #FFFFFF;
  }
  .home_event .prev::before {
    border-bottom: solid 2px #FFFFFF;
    border-left: solid 2px #FFFFFF;
  }
}

/* home_people
********************************************** */
.home_people .section_pdg {
  padding-top: 170px;
  padding-bottom: 75px;
}
.home_people .people_inner {
  margin-bottom: 110px;
}
.home_people .home_ttl {
  margin-right: 4.32vw;
}
.home_people .home_ttl .ttl-01 {
  font-size: rem(42);
  margin-bottom: 24px;
}
.home_people .txtarea {
  flex: 1;
}
.home_people .imgarea {
  position: relative;
  width: 100%;
  height: 21.66vw;
  overflow: hidden;
}
.home_people .people_slider {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  animation: people-slider 100s linear infinite;
}
.home_people .people_slider img {
  width: auto;
  max-width: inherit;
  height: 100%;
}
@include mq(sp) {
  .home_event.section_pdg {
    padding-top: 40px;
    padding-bottom: 50px;
  }
  .home_people .people_inner {
    margin-bottom: 60px;
  }
  .home_people .home_ttl .ttl-01 {
    font-size: 1.8rem;
    margin-bottom: 12px;
  }
  .home_people .home_ttl {
    margin-right: inherit;
    margin-bottom: 20px;
  }
  .home_people .imgarea {
    height: 200px;
  }
}

/* home_about
********************************************** */
.home_about.section_pdg {
  padding-top: 75px;
  padding-bottom: 0;
}
.home_about .about_inner {
  display: grid;
  grid-template-columns: 27.6vw 1fr 27.6vw;
  grid-column-gap: 0;
  width: 100%;
  height: 1230px;
}
.home_about .txtarea {
  padding-top: 40px;
}
.home_about .home_ttl {
  margin-bottom: 5vw;
}
.home_about .txt {
  margin: 0 auto 5.2vw;
}
.home_about .ttl-01 {
  font-size: rem(42);
}
.home_about .ttl-03 {
  margin-left: 36px;
}
.home_about .txt p {
  line-height: 2;
  margin-left: 20px;
}
.home_about .linkarea_wrapper {
  padding-top: calc(48.75vw / 3 - 1.25vw);
}
.home_about .linkarea {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 48.75vw;
}
.home_about .link_box {
  width: calc(33.333% - 1.25vw);
  padding-top: calc(33.333% - 1.25vw);
  display: block;
  border-radius: 50%;
  overflow: hidden;
  isolation: isolate;
  z-index: 0;
}
.home_about .link_box:not(:last-child){
  margin-right: 1.875vw;
}
.home_about .link_box:not(.furniture)::before {
  content: "";
  position: absolute;
  bottom: 26%;
  left: calc(50% - 10px);
  z-index: 1;
  display: block;
  width: 27px;
  height: 27px;
  border-top: solid 3px #FFFFFF;
  border-right: solid 3px #FFFFFF;
  transform: translate(-50%, -50%) rotate(45deg);
}
.home_about .link_box img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: var(--transit-default, all .4s ease-out);
}
.home_about .link_box:hover img:not(.furniture-logo) {
  transform: translate(-50%, -50%) scale(1.05);
}
.home_about .link_box .link-txt {
  position: absolute;
  top: 41%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: $clr-wh;
  line-height: 1;
  font-size: rem(24);
  font-family: ten-mincho, serif;
  font-weight: 400;
  font-style: normal;
  white-space: nowrap;
}
.home_about .link_box.furniture .furniture-logo {
  position: absolute;
  top: 48%;
  width: 56%;
  height: auto;
}
.home_about .link_box.furniture svg {
  position: absolute;
  bottom: 16%;
  left: calc(50% - 13px);
  width: 30px;
  height: 30px;
  fill: #fff;
}
.home_about .imgarea img {
  position: absolute;
}
.home_about .img01 {
  top: 90px;
  left: 0;
  width: min(22.91vw, 440px);
}
.home_about .img02 {
  top: 460px;
  right: 0;
  z-index: -1;
  width: min(19.27vw, 370px);
}
.home_about .img03 {
  bottom: 0;
  left: 0;
  width: min(22.39vw, 419px);
}
.home_about .img04 {
  top: 0;
  left: -10px;
  width: min(18.75vw, 360px);
}
.home_about .img05 {
  top: 470px;
  right: 0;
  width: min(22.47vw, 440px);
}
.home_about .img06 {
  bottom: 70px;
  left: -30px;
  z-index: -1;
  width: min(19.27vw, 370px);
}
@media screen and (max-width: 1600px) {
  .home_about .link_box:not(.furniture)::before {
    bottom: 25%;
  }
}
@media screen and (max-width: 1400px) {
  .home_about .about_inner {
    height: 1100px;
  }
  .home_about .link_box:not(.furniture)::before {
    left: calc(50% - 7px);
    width: 20px;
    height: 20px;
  }
  .home_about .link_box.furniture svg {
    left: calc(50% - 9px);
    width: 18px;
    height: 18px;
  }
  .home_about .ttl-03 {
    margin-left: 14px;
  }
  .home_about .txt p {
    line-height: 1.8;
    margin-left: 8px;
  }
}
@media screen and (max-width: 1200px) {
  .home_about .linkarea_wrapper {
    padding-top: calc(48.75vw / 3 - 4px);
  }
  .home_about .link_box {
    width: calc(33.333% - 4px);
    padding-top: calc(33.333% - 4px);
  }
  .home_about .link_box:not(.furniture)::before {
    bottom: 23%;
  }
}
@include mq(sp) {
  .home_about.section_pdg {
    padding-top: 40px;
    padding-bottom: 80px;
  }
  .home_about .about_inner {
    width: 100%;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 20.6vw;
    grid-template-areas:
      "txtarea txtarea"
      "img1 img2";
    grid-column-gap: 1px;
    height: auto;
  }
  .home_about .txtarea {
    padding-top: inherit;
    grid-area: txtarea;
  }
  .home_about .home_ttl {
    margin-bottom: 30px;
  }
  .home_about .txt {
    margin: 0 auto 20px;
  }
  .home_about .ttl-03 {
    margin-left: 7px;
    font-size: 1.5rem;
  }
  .home_about .txt p {
    font-size: clamp(rem(12), 3.2vw, rem(16));
    line-height: 1.7;
  }
  .home_about .linkarea_wrapper {
    padding-top: 16px;
  }
  .home_about .linkarea {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 410px;
    height:460px;
  }
  .home_about .link_box {
    position: absolute;
    width: 52%;
    padding-top: 52%;
  }
  .home_about .link_box.company {
    left: 0;
    top: 0;
    margin-right: 0;
  }
  .home_about .link_box.real-estate {
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 0;
  }
  .home_about .link_box.furniture {
    left: 0;
    bottom: 0;
  }
  .home_about .link_box:not(.furniture)::before {
    bottom: 18%;
    left: calc(50% - 4px);
    width: 12px;
    height: 12px;
    border-top: solid 2px #FFFFFF;
    border-right: solid 2px #FFFFFF;
  }
  .home_about .link_box.furniture svg {
    left: calc(50% - 8px);
    width: 16px;
    height: 16px;
  }
  .home_about .link_box .link-txt {
    font-size: 1rem;
  }
  .home_about .imgarea {
    height: 120vw;
  }
  .home_about .imgarea:nth-of-type(1) {
    grid-area: img1;
  }
  .home_about .imgarea:nth-of-type(2) {
    grid-area: img2;
  }
  .home_about .img01 {
    top: 0;
    left: 13%;
    width: 88%;
  }
  .home_about .img02 {
    top: 40%;
    right: -17px;
    z-index: -1;
    width: 65%;
  }
  .home_about .img03 {
    bottom: 0;
    left: 0;
    width: 71%;
  }
  .home_about .img04 {
    top: -6%;
    right: 0;
    width: 60%;
  }
  .home_about .img05 {
    top: 30%;
    right: 0;
    width: 60%;
  }
  .home_about .img06 {
    bottom: 5%;
    left: -18%;
    z-index: -1;
    width: 85%;
  }
}

/* home_recruit
********************************************** */
.home_recruit {
  background-image: url(/wp/wp-content/themes/standard_sass/images/top/recruit-bg.jpg);
  overflow: hidden;
}
.home_recruit .recruit_inner  {
  max-width: 1664px;
}
.home_recruit .imgarea  {
  width: 57.69%;
}
.home_recruit .txtarea  {
  width: 546px;
  margin-right: 30px;
}
.home_recruit .home_ttl {
  margin-bottom: 1.25vw;
}
.home_recruit .home_ttl .ttl-01  {
  color: #fff;
  font-size: rem(42);
}
.home_recruit .ttl-02  {
  color: #fff;
  font-size: rem(32);
  margin-bottom: 2.187vw;
}
.home_recruit .txt  {
  margin-bottom: 3.125vw;
  color: #fff;
}

@include mq(1450) {
  .home_recruit .txtarea  {
    width: 37vw;
    padding: 30px 0;
  }
}
@include mq(sp) {
  .home_recruit {
    padding-bottom: 40px;
  }
  .home_recruit .recruit_inner  {
    width: 100%;
  }
  .home_recruit .imgarea  {
    width: 100%;
  }
  .home_recruit .txtarea  {
    width: 90%;
    padding-bottom: 0;
    margin: 0 auto;
  }
  .home_recruit .home_ttl {
    margin-bottom: 16px;
  }
  .home_recruit .home_ttl .ttl-01  {
    font-size: 2.143rem;
  }
  .home_recruit .ttl-02  {
    font-size: 1.875rem;
    margin-bottom: 16px;
  }
  .home_recruit .txt  {
    margin-bottom: 32px;
  }
}
/* home_blog
********************************************** */
.home_blog {
  padding-bottom: 78px;
}
.home_blog .blog_inner {
  max-width: 1282px;
}
.home_blog .home_ttl {
  position: relative;
  margin-top: -56px;
  margin-bottom: 70px;
  z-index: -1;
}
.home_blog .ttlarea {
  margin-bottom: 46px;
}
.home_blog .ttl {
  position: relative;
  padding-left: 26px;
}
.home_blog .ttl::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  background-color: $clr-gre;
}
.home_blog .ttl-04 {
  margin-bottom: 16px;
  color: $clr-gre;
  font-size: rem(28);
  line-height: 1;
  white-space: nowrap;
}
.home_blog .txt {
  color: #535353;
  line-height: 1;
  white-space: nowrap;
}
.home_blog .blog_btn a {
  padding: 6px 0;
  border-bottom: 1px solid $clr-bro;
  color: $clr-bro;
  line-height: 1;
}
.home_blog .blog_btn a:hover {
  color: $clr-gre;
  border-bottom: 1px solid $clr-gre;
}
.home_blog .blog_btn span {
  margin-left: 20px;
}
.home_column, .home_tiding {
  width: calc(50% - 3.59vw);
}
.home_blog .posts-blog .post--img {
  width: 160px;
  margin-right: 24px;
}
.home_blog .posts-blog .post:first-child {
  border-top: inherit;
}
.home_blog .posts-blog .post {
  padding-bottom: 25px;
  padding-top: 25px;
  border-bottom: inherit;
}
.home_blog .post--date {
  line-height: 1;
}
.home_blog .post--ttl {
  margin-bottom: 4px;
}
.home_blog .post--txt {
  line-height: 2;
}
.home_blog .cat_list a {
  padding: 2px 12px;
}

@include mq(sp) {
  .home_blog {
    padding-bottom: 40px;
  }
  .home_blog .home_ttl {
    margin-top: -28px;
    margin-bottom: 40px;
  }
  .home_column, .home_tiding {
    width: 100%;
  }
  .home_column {
    margin-bottom: 32px;
  }
  .home_blog .ttlarea {
    margin-bottom: 16px;
  }
  .home_blog .ttl {
    padding-left: 16px;
  }
  .home_blog .ttl-04 {
    margin-bottom: 8px;
    font-size: rem(24);
  }
  .home_blog .txt {
    line-height: 1.4;
  }
  .home_blog .posts-blog .post--img {
    width: 100%;
    margin-right: inherit;
  }
}

/* home_contact
********************************************** */
.home_contact {
  background-color: #fff;
}
.home_contact .contact_inner {
  z-index: 1;
  max-width: 1600px;
  padding-top: 4.16vw;
  padding-bottom: 5.2vw;
  background-image: url(/wp/wp-content/themes/standard_sass/images/top/contact-bg.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.home_contact .contact-bubble {
  position: absolute;
  top: 1.77vw;
  left: 2.34vw;
}
.home_contact .txtarea {
  width: 560px;
}
.home_contact .txt {
  margin-bottom: 2.39vw;
  line-height: 1;
}
.home_contact .btnarea {
  margin-bottom: 2.08vw;
}
.home_contact .contact_btn a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 272px;
  padding: 21px 30px 19px;
  background-color: $clr-gre;
  color: $clr-wh;
  box-shadow: inherit;
  line-height: 1;
}
.home_contact .contact_btn a:hover {
  background-color: $clr-gre-thin;
}
.home_contact .contact_btn a::before {
  content: inherit;
}
.home_contact .contact_btn .mail-icon {
  width: 20px;
  margin-top: -1px;
  margin-right: 12px;
}
.home_contact .contact_btn .house-icon {
  width: 18px;
  margin-top: -5px;
  margin-right: 12px;
}
.home_contact .tel {
  display: flex !important;
}
.home_contact .tel img {
  width: 27px;
  margin-right: 14px;
  margin-top: 8px;
}
.home_contact .num {
  line-height: 1;
}
.home_contact .open {
  line-height: 1;
}
.home_contact .open span {
  margin-left: 16px;
}
@media screen and (max-width: 1600px) {
  .home_contact .contact-bubble {
    top: 20px;
    left: 20px;
    width: 10vw;
  }
}
@media screen and (max-width: 1300px) {
  .home_contact .contact-bubble {
    display: none;
  }
}
@include mq(sp) {
  .home_contact.section_pdg {
    padding-bottom: 0;
  }
  .home_contact .contact_inner {
    width: 100%;
    padding-top: 40px;
    padding-bottom: 50px;
    background-image: url(/wp/wp-content/themes/standard_sass/images/top/contact-bg_sp.jpg);
  }
  .home_contact .txtarea {
    width: 90%;
  }
  .home_contact .ttl-en {
    font-size: rem(24);
    margin-bottom: 16px;
  }
  .home_contact .txt {
    margin-bottom: 24px;
  }
  .home_contact .btnarea {
    margin-bottom: 24px;
  }
  .home_contact .btn {
    margin: 0 auto;
  }
  .home_contact .tel img {
    width: 20px;
  }
  .home_contact .num {
    font-size: rem(40);
  }
  .home_contact .open span {
    font-size: rem(18);
  }
}