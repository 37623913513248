@use '../abstracts' as *;

/* ##############################################################################

    ARCHIVE

############################################################################## */

/* main_column
**************************************** */
.main_column {
  flex: 1;
  margin-right: 80px;
  overflow: hidden;
}

/* --- post --- */
.post {
  position: relative;
  cursor: pointer;
}
.post--img {
  overflow: hidden;
}
.post--img img {
  transition: all .4s linear;
}
.post:hover .post--img img:not(.post_logo-img) {
  transform: scale(1.05);
}
.post:hover .post--img .post_logo-img {
  transform: translate(-50%, -50%) scale(1.05);
}
.post--ttl {
  font-size: 1.125rem;
  font-family: ten-mincho, serif;
  font-weight: 400;
  font-style: normal;
}
.post--link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.post--date {
  color: #C5D1C6;
  font-family: 'EB Garamond', serif;
  margin-right: 10px;
}
.post--info.flex .post--date + .cat_list {
  margin-left: 14px;
}
.cat_list {
  position: relative;
  z-index: 1;
  overflow: hidden;
} 
.cat_list a {
  display: inline-block;
  vertical-align: middle;
  font-size: 0.813rem; /* 13px */
  background-color: $clr-gre-thin;
  border: 1px solid transparent;
  color: $clr-wh;
  padding: 3px 12px;
  float: left;
  white-space: nowrap;
  margin: 2px;
  line-height: 1;
}
.cat_list a:hover {
  color: $clr-gre-thin;
  background-color: $clr-wh;
  border: 1px solid $clr-gre-thin;
}
.post--txt {
  color: $clr-gra;
}
.post_logo-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50% ,-50%);
  width: 80%;
}

@include mq(1100) {
  .container > .inner.flex {
    display: block;
  }
  .main_column {
    width: 100%;
    margin-bottom: 40px;
    margin-right: 0;
  }
}
@include mq(sp) {
  .post--info.flex .post--date + .cat_list {
    margin-left: 0;
  }
}

/* side_column
**************************************** */
.side_column {
  width: 264px;
  @include mq(1100) {
    width: 100%;
  }
}
.side_section:not(:last-child) {
  margin-bottom: 64px;
}
.side--ttl {
  position: relative;
  letter-spacing: 0.2em;
  line-height: 1;
  padding-left: 16px;
  margin-bottom: 16px;
  color: $clr-gre;
  font-family: ten-mincho, serif;
  font-weight: 400;
  font-style: normal;
}
.side--ttl::before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  background-color: $clr-gre;
}
.side--ttl small {
  opacity: 0.5;
  letter-spacing: 0.15em;
  display: block;
  line-height: 1;
  margin-top: 8px;
  font-family: 'EB Garamond', serif;
}

/* --- list --- */
.side--list a {
  display: block;
}
.side--list a:hover {
  color: $clr-gre;
}

/* --- post --- */
.posts-side .post:not(:last-child) {
  margin-bottom: 16px;
}
.posts-side .post--img {
  position: relative;
  margin-right: 16px;
  width: 64px;
  height: 64px;
}
.posts-side .txtarea {
  flex: 1;
}
.posts-side .post--date {
  margin-bottom: 0;
  line-height: 1.4;
}
.posts-side .post--ttl {
  line-height: 1.4;
}

/* --- archive --- */
.side_column .archive_list--ttl {
  cursor: pointer;
  font-weight: 500;
  line-height: 1.8;
}
.side_column .archive_list--ttl::after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-top: 1px solid;
  border-right: 1px solid;
  transform: rotate(135deg);
  transition: var(--trans-default, all 0.4s ease-out);
}
.side_column .archive_list--ttl.active::after {
  transform: rotate(315deg);
}
.side_column .archive_month {
  display: none;
}

/* --- archive-pulldown --- */
.archive-pulldown {
  position: relative;
  margin-left: auto;
  z-index: 1;
}
.archive-pulldown .archive_list {
  position: relative;
}
.archive-pulldown .archive_list:not(:last-child) {
  margin-right: 24px;
}
.archive-pulldown .archive_list a {
  display: block;
  padding: 0.25em 1em;
  text-align: left;
}
.archive-pulldown .archive_list a:not(:last-child) {
  border-bottom: 1px solid #eee;
}
.archive-pulldown .archive_list a:hover {
  background-color: #eee;
}
.archive-pulldown .archive_list--label {
  margin-right: 8px;
}
.archive-pulldown .archive_list--btn {
  cursor: pointer;
  border: none;
  outline: none;
  appearance: none;
  padding: 0.25em 4em 0.25em 2em;
  background-color: #fff;
  border: 1px solid #ccc;
  font-family: var(--font-primary, "YuGothic", "游ゴシック", "ヒラギノ角ゴ Pro W3",
    "Hiragino Kaku Gothic ProN", sans-serif);
  font-size: 1rem;
  line-height: 1.8;
  letter-spacing: 0.08em;
}
.archive-pulldown .archive_list--btn::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0.4em;
  right: 0.8em;
  margin: auto;
  width: 0.6em;
  height: 0.6em;
  border-bottom: 1px solid;
  border-right: 1px solid;
  transform: rotate(45deg);
  transition: all 0.2s ease-out;
}
.archive-pulldown .active .archive_list--btn::after {
  bottom: -0.2em;
  transform: rotate(225deg);
}
.archive-pulldown .archive_list--menu {
  position: absolute;
  z-index: 1;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  visibility: hidden;
  margin-top: -1px;
  opacity: 0;
  transition: all 0.2s ease-out;
}
.archive-pulldown .active .archive_list--menu {
  visibility: visible;
  opacity: 1;
}


/* blog
**************************************** */
.posts-blog .post {
  padding-bottom: 20px;
  padding-top: 20px;
  border-bottom: 1px solid #e5e5e5;
}
.posts-blog .post:first-child {
  border-top: 1px solid #e5e5e5;
}
.posts-blog .post--txtarea {
  flex: 1;
  word-break: break-word;
}
.posts-blog .post--img {
  width: 280px;
  margin-right: 40px;
}
.posts-blog .post--img::before {
  padding-top: 75%;
}

@include mq(sp) {  
  .posts-blog .post {
    display: block;
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .posts-blog .post--img {
    padding-left: 0;
    @include auto-margin;
    margin-bottom: 16px;
    text-align: center;
  }
  .posts-blog .post--img img {
    width: auto;
    max-width: 100%;
  }
}

/* news
**************************************** */

/* --- news--archive --- */
.news--archive {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #111;
}
.news--archive li {
  @include auto-margin(8);
}
.news--archive a {
  color: #fff;
}

/* --- news_list --- */
.news_list .post {
  padding-bottom: 16px;
  padding-top: 16px;
  margin-bottom: 0;
  border-bottom: 1px solid #dedede;
  @include mq(sp) {
    display: block;
  }
}
.news_list .post--info {
  margin-right: 32px;
  @include mq(sp) {
    margin-bottom: 8px;
    margin-right: 0;
  }
}
.news_list .post--link:not([href="javascript:void(0);"]):hover {
  color: var(--clr-main, #1d7fb7);
}
.news_list .post--link:hover svg {
  fill: var(--clr-main, #1d7fb7);
}
.news_list .post--info.flex .post--date + .cat_list {
  margin-left: 16px;
}

/* works
**************************************** */
.archive-works .main_column {
  overflow: inherit;
}
.archive-works .posts {
  flex-wrap: wrap;
}
.archive-works .posts .post {
  width: 48%;
  margin-bottom: 20px;
  box-shadow: 0 0px 12px 2px rgb(0 0 0 / .03);
}
.archive-works .posts .post--img {
  width: 100%;
  padding-top: 66.666%;
  pointer-events: none;
}
.archive-works .posts .cat_list a:first-child {
  margin-left: 0;
}
.post_work .work--ttl {
  position: absolute;
  bottom: 1.56vw;
  left: 1.56vw;
  z-index: 1;
  width: calc(100% - 3.21vw);
}
.post_work .work--ttl span {
  display: inline;
	padding: .05em .3em;
  color: #fff;
	line-height: 1.818;
	background-color: $clr-gre;
  box-decoration-break: clone;	
	-webkit-box-decoration-break: clone;
}
.post_work .post--txtarea {
  padding: 1vw 1.56vw 1.56vw;
  background-color: $clr-wh;
}
.post_work .txt {
  color: $clr-gra;
  line-height: 2;
}
.post_work .client {
  color: #AAAAAA;
  line-height: 1;
}

.single-works .client {
  color: #AAAAAA;  
}

@include mq(sp) {
  .archive-works .posts .post {
    width: 100%;
  }
  .post_work .work--ttl {
    position: absolute;
    bottom: 16px;
    left: 16px;
    z-index: 1;
    width: calc(100% - 32px);
    font-size: rem(20);
  }
  .post_work .post--txtarea {
    padding: 16px;
  }
}

/* event
**************************************** */
.archive-event .main_column {
  margin-right: 60px;
  padding-top: 20px;
  overflow: inherit;
}
.archive-event .post {
  padding: 20px;
  background-image: url(/wp/wp-content/themes/standard_sass/images/top/sawanohouse-bg.jpg);
}
.archive-event .post:not(:last-child) {
  margin-bottom: 24px;
}
.archive-event .post.close {
  pointer-events: none;
}
.archive-event .post .post--link::before {
  z-index: 1;
}
.archive-event .post.close .post--link::before {
  background-color: $clr-blk;
  opacity: .4;
}
.archive-event .posts .post--img {
  width: 280px;
  height: 186px;
  margin-right: 30px;
}
.archive-event .post--txtarea {
  flex: 1;
}
.event_status {
  position: absolute;
  top: -20px;
  right: -20px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid $clr-gre-thin;
  color: $clr-gre-thin;
  text-align: center;
  font-size: .938rem; 
  font-family: ten-mincho, serif;
  font-weight: 400;
  font-style: normal;
  line-height: 53px;
  letter-spacing: normal;
  z-index: 1;
}
.event_status.close {
  border-color: $clr-gra;
  background-color: #fff;
  color: $clr-gra;
  z-index: 2;
}
.archive-event .cat_list {
  margin-top: 8px;
  margin-left: 8px;
}
.archive-event .post .cat_list {
  z-index: 2;
}
.archive-event .post.close .cat_list {
  z-index: 1;
}
.archive-event .post--ttl {
  font-size: 1.5rem;
}
.archive-event .table1 {
  width: 100%;
}
.archive-event .table1 th,
.archive-event .table1 td {
  padding: 8px;
  font-size: .875rem;
  line-height: 1.4;
}
.archive-event .table1 th {
  width: 60px;
}

// archive-realestate
.archive-realestate .posts {
  flex-wrap: wrap;
}
.archive-realestate .post {
  width: 48%;
  padding-bottom: 48px;
  @include mq(sp) {
    width: 100%;
  }
}
.archive-realestate .post--img::before {
  width: 100%;
  padding-top: 66.666%;
  background-color: #E7E7E8;
}
.archive-realestate .post--ttl {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-size: 1.5rem;
}
.archive-realestate .post--txt dl {
  flex-wrap: wrap;
  gap: 10px 6.6%;
}
.archive-realestate .post--txt dt {
  width: 70px;
  line-height: 24px;
  color: #FFFFFF;
  background-color: $clr-bro;
  text-align: center;
}
.archive-realestate .post--txt dd {
  width: calc(93.4% - 70px);
  min-height: 30px;
  border-bottom: 1px solid $clr-gra;
  padding-left: 10px;
}

@include mq(sp) {
  .archive-event .posts .post--img {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .archive-event .post--txtarea {
    flex: inherit;
    width: 100%;
  }
}