@use '../abstracts' as *;

/* btn
********************************************** */
*:not(.flex) > .btn:not(:last-child) {
  margin-bottom: 8px;
}
.btn a {
  position: relative;
  z-index: 1;
  display: block;
  padding: 21px 50px 21px 40px;
  width: 100%;
  max-width: 310px;
  text-align: left;
  background-color: $clr-wh;
  line-height: 1.4;
  font-family: ten-mincho, serif;
  font-weight: 400;
  font-style: normal;
  box-shadow: 0 0 20px rgba(100,151,108,.3);
  @include mq(sp) {
    max-width: 100%;
  }
}  
.flex > .btn a { max-width: 100%; }
.btn.txt-ctr a {
  @include auto-margin;
}
.btn a:hover {
  color: $clr-wh;
  background-color: $clr-gre;
  box-shadow: inherit;
}
.btn a:where(:not([target="_blank"]):not([href$=".pdf"]):not([href$=".jpg"]):not([href$=".png"]))::before {
  content: '';
  position: absolute;
  top: calc(50% - 4px);
  right: 28px;
  display: block;
  @include rect(8);
  background-color: $clr-gre-thin;
  border-radius: 50%;
  transition: var(--transit-default, all .4s ease-out);
}
.btn a:where(:not([target="_blank"]):not([href$=".pdf"]):not([href$=".jpg"]):not([href$=".png"])):hover::before {
  background-color: $clr-wh;
}
.btn svg {
  fill: $clr-gre-thin;
}
.btn svg:hover {
  fill: $clr-wh;
}
.btn.btn-next_page a {
  max-width: 100%;
  border-radius: 0;
  text-align: left;
}

/* --- btn-wh --- */
.btn-wh a {
  color: var(--btn-clr-wht, #333);
  background-color: var(--btn-bg-wht, #fff);
}
.btn-wh svg {
  fill: var(--btn-clr-wht, #333);
}
.btn-wh a:hover {
  color: var(--btn-clr-wht-hvr, #fff);
  background-color: var(--btn-bg-wht-hvr, #333);
}
.btn-wh a:hover svg {
  fill: var(--btn-clr-wht-hvr, #fff);
}
@include mq(sp) {
  .flex > .btn:not(:last-child) {
    margin-bottom: 8px !important;
  }
}

// pdf ボタン
.btn a[href$=".pdf"] {
  padding: 21px 40px 21px 50px;
}

//下層のページ内リンクボタン
#section-01 .lps_parts--button .btn a::before {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 6px 0 6px;
  border-color: $clr-gre-thin transparent transparent transparent;
  background-color: transparent;
  border-radius: inherit;
  transition: var(--transit-default, all .4s ease-out);
}
#section-01 .lps_parts--button .btn a:hover::before {
  border-color: #fff transparent transparent transparent;
  background-color: transparent;
}

//single-eventのボタン
.single-event .single_btn a {
  color: #fff;
  background-color: $clr-gre;
  box-shadow: 0 0 20px rgba(100,151,108,.3);
}
.single-event .single_btn a:hover {
  color: $clr-gre;
  background-color: #fff;
}
.single-event .single_btn a svg {
  fill: #fff;
}
.single-event .single_btn a:hover svg {
  fill: $clr-gre;
}