@use '../abstracts' as *;

/* ##############################################################################

    HEADER

############################################################################## */

/* header
**************************************** */
.header {
  position: fixed;
  top: 42px;
  left: 0;
  right: 0;
  height: 69px;
  z-index: z(header);
  transition: var(--transit-default, all .4s ease-out);
  & > .inner {
    width: 100%;
    max-width: 100%;
    height: 100%;
    padding-left: 62px;
    padding-right: 62px;
  }
  @include mq(med) {
    top: 0;
    height: 64px;
  }
}
.page-contact .header {
  position: absolute;
}
.header-sm.header {
  top: 0;
  height: 116px;
  background-color: #fff;
  padding-top: 12px;
}
.header--logo a {
  width: 264px;
  display: block;
  padding-top: 3px;
}
.header-sm.header .header--logo a {
  padding-top: 12px;
}
.footer--logo a {
  display: block;
}
.header--logo a:hover,
.footer--logo a:hover {
  opacity: .7;
}
.header--logo_txt {
  margin-bottom: 14px;
  display: block;
  color: #7D7D7D;
  font-size: rem(15);
  line-height: 1;
}

@media screen and (max-width: 1800px) {
  .header > .inner {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media screen and (max-width: 1600px) {
  .header > .inner {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (max-width: 1400px) {
  .header--logo a {
    width: 250px;
  }
}
@media screen and (max-width: 1300px) {
  .header > .inner {
    padding-left: 4px;
    padding-right: 4px;
  }
  .header--logo a {
    width: 200px;
    padding-top: 10px;
  }
  .header-sm.header .header--logo a {
    padding-top: 28px;
  }
  .header--logo_txt {
    margin-bottom: 7px;
    font-size: rem(12);
  }
}
@media screen and (max-width: 1200px) {
  .header--logo a {
    width: 180px;
  }
  .header--logo_txt {
    margin-bottom: 5px;
    font-size: rem(10);
  }
}
@include mq(med) {
  .header > .inner {
    padding-left: 10px;
    padding-right: 10px;
  }
  .header--logo a {
    width: 170px;
    padding-top: 14px;
  }
  .header--logo img {
    width: auto;
    max-height: 40px;
    width: 100%;
  }
  .header-sm.header {
    height: 64px;
    padding-top: 0;
  }
  .header-sm.header .header--logo a {
    padding-top: 14px;
  }
}

/* gnav
********************************************** */
.gnav {
  position: relative;
  padding-top: 23px;
}
.header-sm.header .gnav {
  padding-top: 0;
}
.gnav a {
  display: flex;
  @include center-flex;
}
.gnav--link {
  padding: 0 .9375vw;
  font-family: 'Aoto Gothic Medium', sans-serif;
}
.gnav .gnav--link {
  position: relative;
}
.gnav .gnav--menu > .menu-item >.gnav--link::before {
  content: "";
  position: absolute;
  top: -7px;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 16px;
  background-color: #D8D3C5;
  transition: var(--transit-default, all .4s ease-out);
  opacity: 0;
}
.header-sm.header .gnav .gnav--menu > .menu-item >.gnav--link::before {
  top: 16px;
}
.gnav .menu-item:hover .gnav--link::before {
  opacity: 1;
}
.head_sub_nav--wrap {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  display: block;
  width: 210px;
  transition: var(--transit-default, all .4s ease-out);
  opacity: 0;
  pointer-events: none;
}
.menu-item-has-children {
  position: relative;
}
.head_sub_nav li:not(:last-child) {
  border-bottom: 1px solid $clr-gra;
}
.head_sub_nav a {
  display: block;
  padding: 8px 24px;
}

/* --- SAWANO FURNITURE --- */
.gnav .gnav--furniture {
position: absolute;
top: -4px;
right: 0;
width: 178px;
height: auto;
}

.header-sm.header .gnav .gnav--furniture {
  top: 6px;
}

.gnav--furniture .furniture_link_pc {
  position: relative;
  padding: 10px 22px 10px 6px;
  height: 32px;
  border: 1px solid #bbb;

  &:hover{
    opacity: .7;
  }
}

.gnav--furniture .furniture_link_pc svg,
.gnav--furniture_sp .furniture_link_sp svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 4px;
  width: 10px;
  height: 10px;
  fill: #bbb;
}

.gnav--furniture_sp {
  display: none;

  @include mq(med){
    display: block;
    background-color: var(--bg-wht);
}
}

.gnav--furniture_sp .furniture_link_sp {
  position: relative;
  height: 50px;
  padding: 18px 40px 18px 16px ;

  &:hover{
    opacity: .7;
  }
}

.gnav--furniture_sp .furniture_link_sp img {
  max-width: 174px;
}

.gnav--furniture_sp .furniture_link_sp svg {
  right: 16px;
  width: 14px;
  height: 14px;
}

/* --- SNS --- */
.gnav--sns {
  margin-left: 10px;
}
.gnav--sns .fb {
  margin-right: 14px;
}
.gnav--sns a:hover{
  opacity: .7;
}

/* --- CTA --- */
.header-sm.header .gnav--cta {
  padding-top: 34px;
  padding-bottom: 25px;
}
.cta_tel a {
  padding-bottom: 10px;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: inherit;
  line-height: 1.4;
}
.cta_tel a > span {
  display: block;
  white-space: nowrap;
}
.cta_tel .tel img {
  margin-bottom: 6px;
}
.gnav--tel {
  margin-left: 2.6vw;
  padding-top: 4px;
}
.gnav .gnav--contact {
  width: 178px;
  margin-left: 28px;
  padding-top: 10px;
}
.gnav .gnav--contact a {
  padding: 16px 44px 15px 40px;
  height: 32px;
  background-color: $clr-gre;
  color: $clr-wh;
  box-shadow: inherit;
}
.gnav .gnav--contact a:hover {
  background-color: $clr-gre-thin;
}
.gnav .gnav--contact a img {
  margin-right: 11px;
}
.gnav .gnav--contact a span {
  color: $clr-wh;
  line-height: 1;
  letter-spacing: .1em;
  white-space: nowrap;
}
.gnav--cta .hours {
  letter-spacing: .1em;
}
.gnav--cta .open {
  margin-left: 16px;
}

// 1080px以上の時
@include mq(med, min, ps) {
  .gnav,
  .gnav ul,
  .gnav li,
  .gnav a {
    height: 100%;
  }
  .gnav {
    display: flex;
  }
  .gnav--menu.mid {
    display: none !important;
  }
  .gnav_btn,
  .tel_btn {
    display: none;
  }
  .gnav--link {
    text-align: center;
  }
  .gnav--menu > li > .gnav--link:not([href="javascript:void(0);"]):hover,
  .gnav--menu > li.current-menu-item > .gnav--link {
    color: #1B1B1B;
  }
  .head_sub_nav a {
    color: #fff;
    background-color: $clr-gre;
  }
  .gnav_sub_toggle {
    display: none;
  }
  .gnav .gnav--contact a {
    border-radius: 0;
  }
  /* hover */
  .gnav--menu .menu-item-has-children:hover .head_sub_nav--wrap {
    opacity: 1;
    pointer-events: auto;
  }
  .head_sub_nav a:hover {
    opacity: .7;
  }
}
@media screen and (max-width: 1600px) {
  .gnav--sns {
    margin-left: 4px;
    margin-right: 4px;
  }
  .gnav--sns .fb {
    margin-right: 4px;
  }
  .gnav--tel {
    margin-left: .9259vw;
  }
  .gnav .gnav--furniture{
    width: 140px;
  }
  .gnav .gnav--contact {
    width: 140px;
    margin-left: .9259vw;
  }
  .gnav .gnav--contact a {
    padding: 16px 24px 15px 24px;
  }
}
@media screen and (max-width: 1500px) {
  .gnav--link {
    padding: 0 .7vw;
  }
}
@media screen and (max-width: 1400px) {
  .gnav {
    padding-top: 17px;
  }
  .gnav--link {
    padding: 0 .5555vw;
    font-size: .938rem;
  }
  .gnav .gnav--furniture{
    top: -10px;
  }
  .gnav--tel .num {
    font-size: 1.125rem;
  }
  .gnav--tel .open {
    margin-left: 6px;
  }
}
@media screen and (max-width: 1200px) {
  .gnav {
    padding-top: 0;
  }
  .gnav--link {
    padding: 0 .3vw;
    font-size: rem(14);
  }
  .gnav--tel {
    margin-left: .5vw;
  }
  .gnav--tel .num {
    font-size: 1.125rem;
  }
  header .gnav .gnav--furniture {
    top: -16px;
    width: 100px;
  }
  .header-sm.header .gnav .gnav--furniture {
    top: 10px;
  }
  .gnav .gnav--contact {
    width: 100px;
    padding-top: 20px;
  }
  .header-sm.header .gnav .gnav--contact {
    padding-top: 12px;
  }
  .gnav .gnav--contact a {
    padding: 6px 8px 5px 8px;
  }
  .gnav .gnav--contact a img {
    margin-right: 4px;
    width: 13px;
  }
}
// 1080px以下の時
@include mq(med) {
  .gnav,
  .gnav_overlay {
    position: fixed;
    top: 64px;
    right: 0;
    height: calc(100% - 64px);
    opacity: 0;
    pointer-events: none;
    transition: var(--transit-default, all .4s ease-out);
  }
  .gnav {
    width: 80%;
    margin: 0;
    padding: 24px 7%;
    overflow: scroll;
    z-index: 300;
    transform: translateX(100%);
    -webkit-overflow-scrolling: touch;
    background-image: url(/wp/wp-content/themes/standard_sass/images/top/voice-bg.jpg);
    background-size: cover;
  }
  .header-sm.header .gnav {
    padding-top: 24px;
  }
  .gnav_overlay {
    width: 100%;
    background-color: rgba(255, 255, 255, .8);
  }
  .gnav.active,
  .gnav_overlay.active {
    opacity: 1;
    pointer-events: auto;
  }
  .gnav.active {
    transform: translateX(0);
  }
  .gnav--menu,
  .gnav--cta {
    display: block;
  }
  .gnav--menu
  .gnav--link {
    font-size: 1rem;
  }
  .gnav--menu.pc {
    display: none !important;
  }
  .gnav .gnav--menu > .menu-item >.gnav--link::before {
    content: inherit;
  }
  .gnav--menu li {
    position: relative;
    display: block;
    width: 100%;
  }
  .gnav--menu li:not(:last-child) {
    border-bottom: 1px dotted var(--clr-main, #1d7fb7);
  }
  .gnav--menu a {
    display: block;
    padding: 12px 16px;
  }
  .gnav--menu li:not(.menu-item-has-children) a::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    margin: auto;
    width: 7px;
    height: 7px;
    border-top: 1px solid var(--clr-main, #1d7fb7);
    border-right: 1px solid var(--clr-main, #1d7fb7);
    transform: rotate(45deg);
  }
  .head_sub_nav--wrap {
    position: relative;
    width: 100%;
    border-top: 1px dotted var(--clr-main, #1d7fb7);
    transition: none;
    opacity: 1;
    pointer-events: inherit;
    display: none;
  }
  .menu-item-has-children.active .head_sub_nav--wrap {
    opacity: 1;
    pointer-events: auto;
  }
  .head_sub_nav a {
    padding-left: 32px !important;
  }
  .gnav_sub_toggle {
    position: absolute;
    top: 0;
    right: -20px;
    width: 64px;
    height: 64px;
    transition: var(--transit-default, all .4s ease-out);
    color: var(--clr-main, #1d7fb7);
  }
  .gnav_sub_toggle::before,
  .gnav_sub_toggle::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 11px;
    height: 1px;
    border-bottom: 1px solid;
  }
  .gnav_sub_toggle::after {
    transform: rotate(90deg);
  }
  .gnav_sub_toggle.active {
    transform: rotate(180deg);
  }
  .head_sub_nav {
    position: relative;
  }
  .tel_btn,
  .gnav_btn {
    position: fixed;
    top: 0;
    width: 64px;
    height: 64px;
    border-left: 1px solid #e7e5e4;
    z-index: 9999;
  }
  .tel_btn {
    right: 64px;
  }
  .tel_btn a {
    display: flex !important;
    width: 100%;
    height: 100%;
  }
  .tel_btn svg {
    fill: var(--clr-main, #1d7fb7);
  }
  .gnav_btn {
    right: 0;
    transition: var(--transit-default, all .4s ease-out);
  }
  .gnav_btn.active {
    background-color: #f7f7f7;
  }
  .gnav_btn--lines {
    position: relative;
    width: 24px;
    height: 16px;
  }
  .gnav_btn--lines span {
    transition: var(--transit-default, all .4s ease-out);
    box-sizing: border-box;
  }
  .gnav_btn--lines span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--clr-main, #1d7fb7);
  }
  .gnav_btn--lines span:nth-of-type(1) {
    top: 0;
  }
  .gnav_btn--lines span:nth-of-type(2) {
    top: 7px;
  }
  .gnav_btn--lines span:nth-of-type(3) {
    bottom: 0;
  }
  .active .gnav_btn--lines span:nth-of-type(1) {
    transform: translateY(7px) rotate(-45deg);
  }
  .active .gnav_btn--lines span:nth-of-type(2) {
    opacity: 0;
  }
  .active .gnav_btn--lines span:nth-of-type(3) {
    transform: translateY(-7px) rotate(45deg);
  }

  /* --- SNS --- */
  .gnav--sns {
    padding: 6px;
    margin-left: 0;
    margin-right: 0;
    justify-content: right;
  }
  .gnav--sns .fb {
    margin-right: 10px;
  }

  /* ---CTA --- */
  .header-sm.header .gnav--cta {
    padding-top: 0;
    padding-bottom: 0;
  }
  .gnav--cta li:not(:last-child) {
    margin-bottom: 16px;
  }
  .cta_tel a {
    padding-bottom: 0;
  }
  .gnav--tel {
    margin-left: 0;
    text-align: right;
  }
  .gnav--tel img {
    width: 15px;
  }
  .gnav--tel .num {
    font-size: rem(24);
  }
  .gnav .gnav--furniture{
    display: none;
  }
  .gnav--cta .hours {
    font-size: rem(15);
  }
  .gnav--tel .open {
    font-size: rem(18);
  }
  .gnav .gnav--contact {
    width: 100%;
    padding-top: 0;
    margin-left: 0;
  }
  .header-sm.header .gnav .gnav--contact {
    padding-top: 0;
  }
  .gnav .gnav--contact a {
    height: 50px;
    max-height: inherit;
    padding: 18px 16px;
    margin-left: auto;
    margin-right: auto;
  }
}
@include mq(sp) {
  .gnav_sub_toggle {
    right: -10px;
    width: 52px;
    height: 52px;
  }
}

/* audio_wrap
**************************************** */
.audio_wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100000;
  text-align: center;
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/section-bg.jpg);
  background-size: cover;
  white-space: nowrap;
}
.audio_wrap.none {
  opacity: 0;
  pointer-events: none;
}
.audio_wrap .txtarea {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.audio_wrap .txt p {
  line-height: 1.7;
}
.audio_wrap button {
  width: 180px;
  padding: 8px 20px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 3px;
  color: $clr-bro;
  border: 1px solid $clr-bro;
  background-color: #fff;
}