@use "../abstracts" as *;

/* bg
********************************************** */
.lps_sec:nth-child(2n) {
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/section-bg.jpg);
  background-size: cover;
}
.bg-main {
  background-color: var(--bg-main, #1D7FB7);
}
.bg-sub {
  background-color: #eee;
}
.bg-wh {
  background-color: var(--bg-wht, #fff);
}