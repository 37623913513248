@use '../abstracts' as *;
@use 'sass:math';

/* --- font-size --- */
// フォントサイズを8pxから140pxまでREMで生成
@for $i from 8 to 140 {
  .fz-#{$i} { font-size: math.div($i, 16) + rem}
}
// フォンt-サイズをREM:14引く1px
// これは古い .fz-{数字}のクラスをマネする
@include mq(sp) {
  @for $i from 8 to 140 {
    .fz-#{$i} { font-size: math.div(($i - 1), 14) + rem}
  }
}

/* txt
**************************************** */
.txt-lg { font-size: 1.125rem; /* 18px */ }
.txt-sm { font-size: .875rem; /* 14px */ }
.txt-wh,
.txt-wh * { color: var(--clr-wht, #fff); }
.txt-main,
.txt-main * { color: var(--clr-main, #1d7fb7); }
.txt-sub,
.txt-sub * { color: var(--clr-sub, #eee); }

/* --- font --- */
.font-jp {
  font-family: ten-mincho, serif;
  font-weight: 400;
  font-style: normal;
}
.font-jp1 {
  font-family: 'Aoto Gothic Light', sans-serif;
}
.font-jp2 {
  font-family: 'Aoto Gothic Regular', sans-serif;
}
.font-jp3 {
  font-family: 'Aoto Gothic Medium', sans-serif;
}

.font-en {
  font-family: 'EB Garamond', serif;
}

/* txt
**************************************** */
.txt-ctr,
.txt-ctr-pc {
  text-align: center;
}

.txt-rgt,
.txt-rgt-pc {
  text-align: right;
}

@include mq(sp) {
  .txt-lg { font-size: 1.071rem; /* 15px */ }
  .txt-sm { font-size: .929rem; /* 13px */ }
  .txt-ctr-pc,
  .txt-rgt-pc { text-align: left; }
}

/* 縦書き
**************************************** */
.txt-vl {
  writing-mode: vertical-rl;
}
.txt-vl * {
  font-feature-settings: initial;
}
.txt-vl .num {
  text-combine-upright: all;
}

/* 動き
**************************************** */
.home .anime-op1 {
  opacity: 0;
}
.home .anime-op1.wayshow {
  animation-name: op1;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(.08, .41, .19, .95);
  animation-fill-mode: forwards;
}
.home .anime-op-long {
  opacity: 0;
}
.home .anime-op-long.wayshow {
  animation-name: op1;
  animation-duration: 3s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

/* 背景装飾の動き */
.anime-blur-tra {
  opacity: 0;
}
.anime-blur-tra.wayshow {
  animation-name: blur-tra;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(.08, .41, .19, .95);
  animation-fill-mode: forwards;
}

/* 文字の動き (spanに当てる)*/
.anime-op2 {
  opacity: 0;
}
.anime-op2.wayshow {
  animation-name: op2;
  animation-duration: .5s;
  animation-timing-function: cubic-bezier(.08, .41, .19, .95);
  animation-fill-mode: forwards;
}

/* 文字の装飾の動き*/
.anime-blur {
  opacity: 0;
}
.anime-blur.wayshow {
  animation-name: blur;
  animation-duration: .4s;
  animation-timing-function: cubic-bezier(.08, .41, .19, .95);
  animation-fill-mode: forwards;
}

/* スライドの動き */
.anime-clip-path {
  clip-path: inset(0 100% 0 0);
}
.anime-clip-path.wayshow {
  animation-name: clip-path;
  animation-duration: .4s;
  animation-timing-function: cubic-bezier(.08, .41, .19, .95);
  animation-fill-mode: forwards;
}

/* テキストエリアの動き */
.anime-op-txt {
  transform: translateY(50px);
  opacity: 0;
}
.anime-op-txt.wayshow {
  animation-name: op-txt;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

/* 画像の動き(right) */
.anime-op-img-right {
  transform: translateX(50px);
  opacity: 0;
}
.anime-op-img-right.wayshow {
  animation-name: op-img;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

/* 画像の動き(left) */
.anime-op-img-left {
  transform: translateX(-50px);
  opacity: 0;
}
.anime-op-img-left.wayshow {
  animation-name: op-img;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

/* セクションタイトルの動き */
.anime-op-blur {
  opacity: 0;
  filter: blur(5px);
  transform: scale(1.05, 1.05);
}
.anime-op-blur.wayshow {
  animation-name: op-blur;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

/* セクションタイトルの動き(home_viewのみ) */
.anime-op-blur-tra {
  opacity: 0;
  filter: blur(5px);
  transform: scale(1.05, 1.05);
}
.anime-op-blur-tra.wayshow {
  animation-name: op-blur-tra;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

/* animation-duration: 時間(s); */
.anime-del01 {
  animation-delay: .1s;
}
.anime-del02 {
  animation-delay: .2s;
}
.anime-del03 {
  animation-delay: .3s;
}
.anime-del04 {
  animation-delay: .4s;
}
.anime-del05 {
  animation-delay: .5s;
}
.anime-del06 {
  animation-delay: .6s;
}
.anime-del07 {
  animation-delay: .7s;
}
.anime-del08 {
  animation-delay: .8s;
}
.anime-del09 {
  animation-delay: .9s;
}
.anime-del10 {
  animation-delay: 1s;
}
.anime-del20 {
  animation-delay: 2s;
}
.anime-del21 {
  animation-delay: 2.1s;
}
.anime-del22 {
  animation-delay: 2.2s;
}
.anime-del23 {
  animation-delay: 2.3s;
}
.anime-del24 {
  animation-delay: 2.4s;
}
.anime-del25 {
  animation-delay: 2.5s;
}
.anime-del26 {
  animation-delay: 2.6s;
}
.anime-del27 {
  animation-delay: 2.7s;
}
.anime-del28 {
  animation-delay: 2.8s;
}
.anime-del29 {
  animation-delay: 2.9s;
}
.anime-del30 {
  animation-delay: 3s;
}
.anime-del31 {
  animation-delay: 3.1s;
}
.anime-del32 {
  animation-delay: 3.2s;
}
.anime-del33 {
  animation-delay: 3.3s;
}
.anime-del34 {
  animation-delay: 3.4s;
}
.anime-del35 {
  animation-delay: 3.5s;
}
.anime-del36 {
  animation-delay: 3.6s;
}
.anime-del37 {
  animation-delay: 3.7s;
}
.anime-del38 {
  animation-delay: 3.8s;
}
.anime-del39 {
  animation-delay: 3.9s;
}
.anime-del40 {
  animation-delay: 4s;
}
.anime-del41 {
  animation-delay: 4.1s;
}
.anime-del42 {
  animation-delay: 4.2s;
}
.anime-del43 {
  animation-delay: 4.3s;
}
.anime-del44 {
  animation-delay: 4.4s;
}