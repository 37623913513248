@use "sass:map";

// SASS用の基本設定変数

// デザイン上の幅
$designWidth: 1920px;

// 色
$clr-bro: #423622;
$clr-bro-thin: #B3A98C;
$clr-gre: #235A3E;
$clr-gre-thin: #64976C;
$clr-bro: #423622;
$clr-blk: #333333;
$clr-wh: #FFFFFF;
$clr-gra: #A1A1A1;

// font-families
$font-primary: "こぶりなゴシック W6 JIS2004", "Koburina Gothic W6 JIS2004";  
$font-secondary: "ヒラギノ角ゴ オールド W6 JIS2004", "Hiragino Sans Old W6 JIS2004";
$font-secondary--light: "ヒラギノ角ゴ オールド W3 JIS2004", "Hiragino Sans Old W3 JIS2004";
$font-jp: "こぶりなゴシック W6 JIS2004", "Koburina Gothic W6 JIS2004";
$font-en: 'Josefin Sans', sans-serif;
$font-noto-sans: 'Noto Sans JP', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro','メイリオ', 'Meiryo', '游ゴシック', 'Yu Gothic', 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;

// CSS変数
:root {
  // typography
  --ltr-space-default: .08em;
  --line-height-default: 1.8;
  --line-height-hdr: 1.4;
  --line-height-none: 1;

  // font-families
  --font-primary: "こぶりなゴシック W6 JIS2004", "Koburina Gothic W6 JIS2004";  
  --font-secondary: "ヒラギノ角ゴ オールド W6 JIS2004", "Hiragino Sans Old W6 JIS2004";
  --font-secondary--light: "ヒラギノ角ゴ オールド W3 JIS2004", "Hiragino Sans Old W3 JIS2004";
  --font-jp: "こぶりなゴシック W6 JIS2004", "Koburina Gothic W6 JIS2004";
  --font-en: 'Josefin Sans', sans-serif;
  --font-noto-sans: 'Noto Sans JP', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro','メイリオ', 'Meiryo', '游ゴシック', 'Yu Gothic', 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;

  // font-sizes
  --fz-primary: 1.125rem;
  --fz-half: .5rem;



  // text colors
  --clr-main: #423622;
  --clr-txt-sub: #171C60;
  --clr-body: #171923;
  --clr-dark-blue: #101560;
  --clr-link: #333;
  --clr-wht: #fff;
  --clr-blk: #000;

  // background colors
  --bg-main: #235A3E;
  --bg-sub: #eee;
  --bg-wht: #fff;
  --bg-off_wht: #dcedfc;
  --bg-blk: #000;

  //button colors
  --btn-bg-main: var(--clr-main);
  --btn-bg-main-hvr: var(--clr-dark-blue);
  --btn-clr-main: #fff;
  --btn-clr--sec: #fff;

  --btn-clr-wht: #333;
  --btn-bg-wht: #fff;
  --btn-clr-wht-hvr: #fff;
  --btn-bg-wht-hvr: var(--clr-dark-blue);


  // border colors
  --brd-main: #1D7FB7;

  // animation
  --transit-default: all 0.4s ease-out;


  // formy settings

}

// SASS Maps

$z-index: (
  "header" : 9999,
  "top": 9990,
  "aside" : 75,
  "middle" : 50,
  "main" : 1,
  "base" : 0,
  "under": -1,
  "deep": -100,
  "bottom": -999,
);

$em-breakpoints: (
  xl: 160, // 2560px
  lg: 120, // 1920px
  pc: 85.375, // 1366px
  med: 67.5, // 1080px
  sp: 47.9375, // 767px
  sm: 40, // 640px
  xs: 34.375, // 500px
  se: 21.25, // 350px
);
$px-breakpoints: (
  xl: 2560,
  lg: 1920,
  pc: 1366,
  med: 1080,
  sp: 767,
  sm: 640,
  xs: 500,
  se: 350,
);

$colors: (
  'blue': (
    100: hsl(202, 73%, 82%),
    200: hsl(202, 73%, 72%),
    300: hsl(202, 73%, 62%),
    400: hsl(202, 73%, 52%),
    500: hsl(202, 73%, 42%),
    600: hsl(202, 73%, 32%),
  ),
  'red': (
    100: hsl(0, 100%, 80%),
    200: hsl(0, 100%, 70%),
    300: hsl(0, 100%, 60%),
    400: hsl(0, 100%, 50%),
    500: hsl(0, 100%, 40%),
    600: hsl(0, 100%, 30%),
  ),
  'green': (
    100: hsl(118, 100%, 80%),
    200: hsl(118, 100%, 70%),
    300: hsl(118, 100%, 60%),
    400: hsl(118, 100%, 50%),
    500: hsl(118, 100%, 40%),
    600: hsl(118, 100%, 30%),
  )
);